<template>
  <div
    :class="`${isLoading ? 'overlay' : ''} overlay-block rounded animation`"
    style="height: auto;"
  >
    <div :style="{ height: isLoading ? '40vh' : '100%', overflow: 'visible' }">
      <div v-if="!hasLegends" style="height: 44px"></div>
      <div :class="`${isLoading ? 'overlay-layer' : ''} rounded `">
        <div :class="`${isLoading ? 'spinner' : ''} spinner-primary`"></div>
      </div>
      <div
        :style="{
          position: 'absolute',
          top: '60%',
          left: '51%',
          transform: 'translate(-50%,-50%)',
          textAlign: 'center',
        }"
        v-if="isLoading"
      >
        <p :style="{ color: isLoading && data ? '#eee' : '#555' }">
          Vui lòng chờ đợi trong giây lát
        </p>
      </div>
      <div
        class="overlay-wrapper p-5"
        :id="id"
        style="position: relative;text-align: center"
      ></div>
      <h5 style="text-align: center">{{ title }}</h5>
    </div>
  </div>
</template>
<script>
export default {
  name: 'D3ArrayChartContainer',
  props: {
    isLoading: {},
    id: {},
    title: {
      type: String,
      defaultValue: '',
    },
    data: {
      type: Array,
      defaultValue: [],
    },
    hasLegends: {
      type: Boolean,
      defaultValue: false,
    },
  },
  computed: {
    isNotFetching() {
      return this.isLoading && this.data && this.data.length > 0 && this.title;
    },
  },
};
</script>
<style scoped>
.animation {
  transition: height 0.3ms;
}
</style>
