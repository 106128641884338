import { assign, cloneDeep, find, map } from 'lodash';
import { removeAccents } from '@/utils/common';

const vueMultiselectMixin = {
  methods: {
    selectResourceHandler({
      option,
      options,
      trackBy = 'id',
      // eslint-disable-next-line no-unused-vars
      filteredOptions, // handles changing dynamic filteredOptions in data prop of Vue component
      checked,
    }) {
      let index = options.findIndex(
        (item) => item[trackBy] === option[trackBy],
      );
      options[index].checked = checked;
      filteredOptions = cloneDeep(options);
    },
    inputChangeHandler({
      textInput = '',
      resourceOptions,
      filteredResourceOptions,
      trackBy,
    }) {
      this.searchResource({
        textInput,
        resourceOptions,
        filteredResourceOptions,
        trackBy,
      });
    },
    searchResource({
      textInput,
      resourceOptions,
      filteredResourceOptions,
      trackBy = 'id',
    }) {
      let options = cloneDeep(resourceOptions);
      if (!textInput || !textInput.trim().length) {
        filteredResourceOptions = map(options, (obj) => {
          return assign(
            obj,
            find(filteredResourceOptions, { [trackBy]: obj[trackBy] }),
          );
        });
        return;
      }

      options = map(options, (obj) => {
        return assign(
          obj,
          find(filteredResourceOptions, { [trackBy]: obj[trackBy] }),
        );
      });

      filteredResourceOptions = this.filterOptionsBy(
        options,
        textInput,
        'name',
        10,
      );
    },
    filterOptionsBy(items, textInput, prop = 'name', limit) {
      return cloneDeep(items)
        .filter((item) => {
          if (item) {
            const nameWTUnicode = removeAccents(item[prop] || '');
            const nameInputWTUnicode = removeAccents(textInput);
            const index = nameWTUnicode
              .toLowerCase()
              .indexOf(nameInputWTUnicode.toLowerCase());

            console.log(index);
            if (index > -1) {
              return true;
            }
          }
          return false;
        })
        .slice(0, limit);
    },
  },
};
export default vueMultiselectMixin;
