var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:`${_vm.isLoading ? 'overlay' : ''} overlay-block rounded animation`,staticStyle:{"height":"auto"}},[_c('div',{style:({ height: _vm.isLoading ? '40vh' : '100%', overflow: 'visible' })},[(!_vm.hasLegends)?_c('div',{staticStyle:{"height":"44px"}}):_vm._e(),_c('div',{class:`${_vm.isLoading ? 'overlay-layer' : ''} rounded `},[_c('div',{class:`${_vm.isLoading ? 'spinner' : ''} spinner-primary`})]),(_vm.isLoading)?_c('div',{style:({
        position: 'absolute',
        top: '60%',
        left: '51%',
        transform: 'translate(-50%,-50%)',
        textAlign: 'center',
      })},[_c('p',{style:({ color: _vm.isLoading && _vm.data ? '#eee' : '#555' })},[_vm._v(" Vui lòng chờ đợi trong giây lát ")])]):_vm._e(),_c('div',{staticClass:"overlay-wrapper p-5",staticStyle:{"position":"relative","text-align":"center"},attrs:{"id":_vm.id}}),_c('h5',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.title))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }