<template>
  <b-form-group>
    <Treeselect
      :name="name"
      :multiple="multiple"
      :clearable="clearable"
      :searchable="searchable"
      :disabled="disabled"
      :open-on-click="openOnClick"
      :open-on-focus="openOnFocus"
      :clear-on-select="clearOnSelect"
      :close-on-select="closeOnSelect"
      :always-open="alwaysOpen"
      :append-to-body="appendToBody"
      :options="options"
      :limit="limit"
      :max-height="maxHeight"
      v-model="bindingModel"
      :placeholder="placeHolder"
      retryText="Thử lại..."
      retryTitle="Nhấp thử lại"
      searchPromptText="Nhập để tìm kiếm..."
      loadingText="Đang tải..."
      noResultsText="Không có kết quả"
      :openDirection="openDirection"
      :noChildrenText="noChildrenText"
      :load-options="loadOptions"
      :limit-text="limitText"
      :noOptionsText="noOptionsText"
    />
  </b-form-group>
</template>
<script>
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import Treeselect from '@riophae/vue-treeselect';

export default {
  components: {
    Treeselect,
  },
  props: {
    model: {
      default: null,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    searchable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    openOnClick: {
      type: Boolean,
      default: true,
    },
    openOnFocus: {
      type: Boolean,
      default: false,
    },
    clearOnSelect: {
      type: Boolean,
      default: true, // false when multiple is true
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    alwaysOpen: {
      type: Boolean,
      default: false,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
    name: {
      type: String,
      default: 'treeselect',
      required: true,
    },
    value: {
      type: [String, Number, Array],
      default: null,
    },

    limit: {
      type: Number,
      default: 3,
    },
    maxHeight: {
      type: Number,
      default: 200,
    },
    retryText: {
      type: String,
      default: 'Thử lại...',
    },
    retryTitle: {
      type: String,
      default: 'Nhấp để thử lại',
    },
    searchPromptText: {
      type: String,
      default: 'Nhập để tìm kiếm...',
    },
    loadingText: {
      type: String,
      default: 'Đang tải...',
    },
    noResultText: {
      type: String,
      default: 'Không có kết quả',
    },
    openDirection: {
      type: String,
      default: 'bottom',
    },

    loadOptions: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      placeHolder: '',
      noChildrenText: '',
      limitText: () => {},
      noOptionsText: '',
    };
  },
  methods: {
    initialLoad() {
      this.placeHolder = `Lọc theo ${this.name}`;
      this.noChildrenText = `Không có ${this.name} con`;
      this.limitText = (count) => `và ${count} ${this.name} khác`;
      this.noOptionsText = this.noChildrenText;
    },
  },
  computed: {
    bindingModel: {
      get: function() {
        return this.model;
      },
      set: function(newValue) {
        this.$emit('update:model', newValue);
        return;
      },
    },
  },
  mounted() {
    this.initialLoad();
  },
};
</script>

